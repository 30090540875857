
<script setup>
    import { onMounted, onUnmounted, ref, computed } from 'vue';
    import { getDataObjectById, getOrCreateDataObject } from 'o365.vue.ts'
    import VueRouter from 'vue-router';
    import { useScope } from "mobile.utils.ts";
    import MActionSheet from "mobile.vue.components.ActionSheet.vue";
    import MActionSheetBtn from "mobile.vue.components.ActionSheetBtn.vue";

    const props = defineProps({
        modelValue: {},
    });
    const emit = defineEmits(["update:modelValue"]);

    const viewNameMyTypeGroups = useScope ? "aviw_Scope_MyTypeGroups" : "aviw_Workflow_MyTypeGroups";
    const viewNameMyTypes = useScope ? "aviw_Scope_MyTypes" : "aviw_Workflow_MyTypes";

    const dsGroups = getOrCreateDataObject({
        "id": "mobile.vue.components.workflow.TypesSheet.vue:dsGroups",
        "viewName": viewNameMyTypeGroups,
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {
                "name": "ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Name",
                "sortOrder": 1,
                "sortDirection": "asc",
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            }
        ],
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": -1,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });

    const dsMyTypes = getOrCreateDataObject({
        "id": "dsMyTypes",
        "viewName": viewNameMyTypes,
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {
                "name": "ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Name",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "AppName",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Description",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Group_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            }
        ],
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": -1,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });

    const groups = computed(() => {
        // group types
        const groups = dsGroups.data.map(group => {
            return {
                name: group.Name,
                types: dsMyTypes.data.filter(e => e.Group_ID === group.ID),
                //show: false,
            };
        });

        // other types
        groups.push({
            name: $t("Other workflow types"),
            types: dsMyTypes.data.filter(e => e.Group_ID == null),
            //show: false,
        });

        return groups.filter(e => e.types.length);
    });

    const model = computed({
        get: () => props.modelValue,
        set: (val) => emit("update:modelValue", val),
    });

    /* Testing for grouping:
    const toggleShowTypes = (inputGroup) => {
        inputGroup.show = true;
        groups.value.forEach((group) => {
            if (group.name != inputGroup.name) {
                group.show = false;
            }
        });
    };
    */

    onMounted(async () => {
        // reset state
        dsGroups.state.isLoaded = false;
        dsMyTypes.state.isLoaded = false;
        
        await dsGroups.load();
        await dsMyTypes.load();
    });

</script>

<template>
    <MActionSheet v-model="model">
        <!--
        <template #header>
            <div class="p-3 text-muted text-center">
                {{ $t("Create workflow from type, or create new workflow") }}
            </div>
        </template>
        -->
        <template v-for="group in groups">

            <!-- Testing for grouping: -->
            <!--<MActionSheetBtn class="fw-medium text-primary" :text="group.name" @click="toggleShowTypes(group)"/>-->
            <!--<template v-for="type in group.types" v-if="group.show">
                <MActionSheetBtn class="fw-medium text-primary" :text="type.Name" :to="{ path: '/workflow/new', query: { 'Type_ID': type.ID } }" />
            </template>
            -->

            
            <template v-for="type in group.types">
                <MActionSheetBtn class="fw-medium text-primary" :text="type.Name" :to="{ path: '/workflow/new', query: { 'Type_ID': type.ID } }" />
            </template>
            
        </template>
        <MActionSheetBtn class="" :text="$t('Create new workflow')" to="/workflow/new" />
    </MActionSheet>
</template>
